// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-header ion-toolbar {
  --background: var(--ion-color-primary);
  --ion-color-base: var(--ion-color-primary) !important;
}

ion-avatar {
  --border-radius: 0 !important;
}

.btn-remove-photo {
  position: absolute;
  left: 62%;
  bottom: -10%;
}

h2 {
  color: var(--ion-color-primary);
}

.image-ad {
  border-radius: 10px;
  box-shadow: 5px 10px 18px #d0cfcf;
}

ion-label {
  --color: #000;
}

lord-icon {
  width: 128px;
  height: 128px;
}

.card-type-ad {
  border-radius: 25px;
}

.item.sc-ion-label-md-h, .item .sc-ion-label-md-h, .item.sc-ion-label-ios-h, .item .sc-ion-label-ios-h {
  white-space: normal;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/admin/new-ad/new-ad.page.scss"],"names":[],"mappings":"AACI;EACI,sCAAA;EACA,qDAAA;AAAR;;AAGA;EACI,6BAAA;AAAJ;;AAEA;EACI,kBAAA;EACA,SAAA;EACA,YAAA;AACJ;;AACA;EACI,+BAAA;AAEJ;;AAAA;EACI,mBAAA;EACA,iCAAA;AAGJ;;AADA;EACI,aAAA;AAIJ;;AAFA;EACI,YAAA;EACA,aAAA;AAKJ;;AAHA;EACI,mBAAA;AAMJ;;AAHA;EACI,mBAAA;AAMJ","sourcesContent":["ion-header{\r\n    ion-toolbar {\r\n        --background: var(--ion-color-primary);\r\n        --ion-color-base: var(--ion-color-primary) !important;\r\n    }\r\n}\r\nion-avatar{\r\n    --border-radius: 0 !important;\r\n}\r\n.btn-remove-photo{\r\n    position: absolute;\r\n    left: 62%;\r\n    bottom: -10%;\r\n}\r\nh2{\r\n    color: var(--ion-color-primary);\r\n}\r\n.image-ad{\r\n    border-radius: 10px;\r\n    box-shadow: 5px 10px 18px #d0cfcf;\r\n}\r\nion-label{\r\n    --color: #000;\r\n}\r\nlord-icon {\r\n    width: 128px;\r\n    height: 128px;\r\n}\r\n.card-type-ad{\r\n    border-radius: 25px;\r\n}\r\n\r\n.item.sc-ion-label-md-h, .item .sc-ion-label-md-h, .item.sc-ion-label-ios-h, .item .sc-ion-label-ios-h{\r\n    white-space: normal;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
