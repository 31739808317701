// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ion-list.list-ios {
  margin-bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/components/notifications/notifications.component.scss"],"names":[],"mappings":"AACI;EACI,gBAAA;AAAR","sourcesContent":[".ion-list {\r\n    &.list-ios {\r\n        margin-bottom: 0;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
