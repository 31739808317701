import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "../../environments/environment";
import { LoginService } from './login.service';

@Injectable({
    providedIn: 'root'
})
export class AdminService {

    constructor(
        private http: HttpClient,
        private loginService: LoginService
    ) { }

    getToken() {
        return this.loginService.getToken();
    }

    // GET: Construir la llamada a la API
    getQuery(query: string) {
        const url = environment.api;
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.getToken(),
        });

        return this.http.get(url + 'admin/' + query, { headers });
    }

    // POST: Construir la llamada a la API
    postQuery(query: string, params = {}) {
        const url = environment.api;
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.getToken(),
        });

        return this.http.post(url + 'admin/' + query, params, { headers });
    }

    loadTicketCode(code) {
        const query = "check_ticket_code";

        const params = {
            code,
        };

        return this.postQuery(query, params);
    }

    validateTicket(code) {
        const query = "validate_ticket";

        const params = {
            code,
        };

        return this.postQuery(query, params);
    }

    validateFastTicket(params) {
        const query = "validate_ticket";

        return this.postQuery(query, params);
    }

    getAdsToFastValidate() {
        const query = "choose_tickets_to_validate";

        const params = {};

        return this.postQuery(query, params);
    }

    buildFormAd(type_id, ad_id = null) {
        const query = "build_form_ad/" + type_id + "/" + ad_id;

        return this.getQuery(query);
    }

    createUpdateAd(data, update_ad = false) {
        const query = (update_ad) ? "update_ad" : "create_ad";

        const params = {
            data,
        };

        return this.postQuery(query, params);
    }

    getAds(params = {}) {
        const query = "get_ads";

        return this.postQuery(query, params);
    }

    getConfigAd(id) {
        const query = "get_config_ad/"+id;

        return this.getQuery(query);
    }

    setPublishAd(id, is_publish) {
        const query = "set_publish_ad/"+id+'/'+is_publish;

        return this.getQuery(query);
    }

    getDeleteAd(id) {
        const query = "delete_ad/"+id;

        return this.getQuery(query);
    }

    getTicketsListAd(id, ads_date_id) {
        const query = "get_tickets_list_ad/"+id+'/'+ads_date_id;

        return this.getQuery(query);
    }

    getTicketsListAdCSV(id, ads_date_id) {
        const query = "get_tickets_list_ad_export_csv/"+id+'/'+ads_date_id;

        return this.getQuery(query);
    }

    getEmployees() {
        const query = "get_employees";

        return this.getQuery(query);
    }

    saveEmployee(params) {
        const query = "save_employee";

        return this.postQuery(query, params);
    }

    deleteEmployee(id) {
        const query = "delete_employee/" + id;

        return this.getQuery(query);
    }

    buildFormBusinessAddress() {
        const query = "build_form_business_address";

        return this.getQuery(query);
    }

    createUpdateBusinessAddress(data, update_ad = false) {
        const query = (update_ad) ? "update_business_address" : "create_business_address";

        const params = {
            data,
        };

        return this.postQuery(query, params);
    }

    deleteBusinessAddress(data) {
        const query = "delete_business_address";

        const params = {
            data,
        };

        return this.postQuery(query, params);
    }

    createUpdateBusinessIBAN(data, update_ad = false) {
        const query = (update_ad) ? "update_business_account" : "create_business_account";

        const params = {
            data,
        };

        return this.postQuery(query, params);
    }

    getBusinessIBAN() {
        const query = "get_account_business_app";
        return this.getQuery(query);
    }
}
