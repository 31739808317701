// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  android_version: '6', // debe ser la misma que en android studio y la misma que en el parametro global
  android_url: 'https://play.google.com/store/apps/details?id=com.halterofiliaburgos.app',
   // api: 'http://localhost:8000/api/',
    api: 'https://gestion.neopilates.es/api/',
    firebaseConfig: {
        apiKey: "AIzaSyBvubadQ1YUelvOd0lMt_QMsikNJYvDunI",
        authDomain: "neopilates-c554c.firebaseapp.com",
        projectId: "neopilates-c554c",
        storageBucket: "neopilates-c554c.appspot.com",
        messagingSenderId: "344385888832",
        appId: "1:344385888832:web:b639ac0483f36380f135a5",
        measurementId: "G-L5TZ2WG8F8"
    },
   // stripe_public_key: 'pk_test_51I3r8WK37QnGjuXwQpZ2tbGZcUqmF0uc1sZAQcCAeTCKSGCopvmFpcrw977Eo87ydVBE0rEOg6GdgFFWjHDNZsHJ00eJmUNrqV'
   stripe_public_key: 'pk_live_Amt4BSpMdF13rSyCugwcXr1k00q1XojiUl'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
