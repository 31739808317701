import {Session} from "./session";

export class User{
    id: number;
    name: string;
    role_id: number;
    estado: number;
    startdate_descanso: string;
    enddate_descanso: number;
    email: string;
    avatar: string;
    accept_notifications: boolean;
    showBooking: boolean;
    sessiones: Session[] = [];

    constructor(id, name, roleId, email, avatar, acceptNotifications?){
        this.id = id;
        this.name = name;
        this.role_id = roleId;
        this.email = email;
        this.avatar = avatar;
        this.accept_notifications = acceptNotifications;
    }

    public setEstado(est){
        this.estado = est;
    }
    public setEstadoInicioFin(startdatedescanso, enddatedescanso){
        // startdate_descanso:"2024-07-15"
        this.startdate_descanso = startdatedescanso;
        this.enddate_descanso = enddatedescanso;
    }
}
