// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h2 {
  color: var(--ion-color-primary);
}

.div-loading {
  padding-top: 15%;
}`, "",{"version":3,"sources":["webpack://./src/app/components/loading/loading.component.scss"],"names":[],"mappings":"AAAA;EACI,+BAAA;AACJ;;AACA;EACI,gBAAA;AAEJ","sourcesContent":["h2{\r\n    color: var(--ion-color-primary)\r\n}\r\n.div-loading{\r\n    padding-top: 15%;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
