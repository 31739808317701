import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProductoTarjetaComponent } from './producto-tarjeta/producto-tarjeta.component';
import { GooglemapsComponent } from './googlemaps/googlemaps.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { PopmenuComponent } from './popmenu/popmenu.component';
import { PopticketComponent } from './popticket/popticket.component';
import { StepProgressBarComponent } from './step-progress-bar/step-progress-bar.component';
import { LoadingComponent } from './loading/loading.component';
import { NoDataComponent } from './no-data/no-data.component';
import { FormBusinessAddressComponent } from './form-business-address/form-business-address.component';
// import { QrScannerComponent } from './qr-scanner/qr-scanner.component';

@NgModule({
  declarations: [
    ProductoTarjetaComponent,
    GooglemapsComponent,
    NotificationsComponent,
    PopmenuComponent,
    PopticketComponent,
    StepProgressBarComponent,
    LoadingComponent,
    NoDataComponent,
    FormBusinessAddressComponent,
    // QrScannerComponent
  ],
  exports: [
    ProductoTarjetaComponent,
    GooglemapsComponent,
    NotificationsComponent,
    PopmenuComponent,
    PopticketComponent,
    StepProgressBarComponent,
    LoadingComponent,
    NoDataComponent,
    FormBusinessAddressComponent,
    // QrScannerComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
  ]
})
export class ComponentsModule { }
