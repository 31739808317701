import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

// Importaciones
import { IonicStorageModule } from '@ionic/storage';

// Traducciones
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import {  TranslateHttpLoader } from '@ngx-translate/http-loader';

// Exportar ficheros xlxs
import { File } from '@ionic-native/file/ngx';

// QR
import { QRCodeModule } from 'angularx-qrcode';
// import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';

// Pipe
import { DatePipe } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

// Abrir navegador
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

// Social Sharing
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
// Google Adsense (AdMob)
// import { AdMobFree } from '@ionic-native/admob-free/ngx';
// Modales
import { ValidateCodeModalPageModule } from './pages/admin/validate-code-modal/validate-code-modal.module';
// import { FastScannerQrPageModule } from './pages/admin/fast-scanner-qr/fast-scanner-qr.module';
import { NewAdPageModule } from './pages/admin/new-ad/new-ad.module';
import { CheckoutPageModule } from './pages/checkout/checkout.module';
import { Stripe } from '@ionic-native/stripe/ngx';

// Login apple

// IMPORT OFFICIAL ANGULAR FIRE AND THE ENVIRONMENT TO LOAD FIREBASE.
// import { AngularFireModule } from '@angular/fire';
// import { AngularFireAuthModule } from '@angular/fire/auth';
// import { AngularFirestoreModule } from '@angular/fire/firestore';

// IMPORT APPLE SIGN IN
// import { SignInWithApple } from '@ionic-native/sign-in-with-apple/ngx';
// import { SignInWithApple } from  '@awesome-cordova-plugins/sign-in-with-apple/ngx';
import { SignInWithApple} from '@awesome-cordova-plugins/sign-in-with-apple/ngx';

export function newTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

import { defineLordIconElement } from 'lord-icon-element';
import lottie from 'lottie-web';

import { Title } from '@angular/platform-browser';
import {AccountPageModule} from './pages/admin/account/account.module';
import {SharedService} from "./services/shared.service";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    // IonicStorageModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: newTranslateLoader,
        deps: [HttpClient]
      }
    }),
    // QRCodeModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    ValidateCodeModalPageModule,
    // FastScannerQrPageModule,
    NewAdPageModule,
    CheckoutPageModule,
    AccountPageModule,
  ],
  providers: [
    SharedService,
    StatusBar,
    SplashScreen,
    DatePipe,
    // BarcodeScanner,
    SocialSharing,
    // AdMobFree,
    Stripe,
    Title,
    InAppBrowser,
    File,
    SignInWithApple,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    defineLordIconElement(lottie.loadAnimation);
  }
}
