// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-footer ion-toolbar {
  --background: transparent;
  --ion-color-base: transparent !important;
}

ion-item.no-border {
  --border-color: transparent;
}

.total_price {
  color: var(--ion-color-primary);
  font-weight: bold;
  font-size: 1.5rem;
}

.row-title {
  color: var(--ion-color-primary);
}

.btn-promotional-code {
  text-decoration: underline;
}

.btn-delete-promotional-code {
  color: var(--ion-color-danger);
  text-decoration: underline;
}

.text-discount {
  color: var(--ion-color-success);
}

.title-promotional-code {
  font-style: italic;
}

.item.sc-ion-label-ios-h, .item .sc-ion-label-ios-h, .item.sc-ion-label-md-h, .item .sc-ion-label-md-h {
  white-space: normal;
}

.alert-ticket-personal-data .alert-wrapper {
  overflow: scroll;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/checkout/checkout.page.scss"],"names":[],"mappings":"AACI;EACI,yBAAA;EACA,wCAAA;AAAR;;AAKI;EACI,2BAAA;AAFR;;AAKA;EACI,+BAAA;EACA,iBAAA;EACA,iBAAA;AAFJ;;AAKA;EACE,+BAAA;AAFF;;AAKA;EACI,0BAAA;AAFJ;;AAIA;EACI,8BAAA;EACA,0BAAA;AADJ;;AAGA;EACI,+BAAA;AAAJ;;AAEA;EACI,kBAAA;AACJ;;AACA;EACI,mBAAA;AAEJ;;AAIA;EACI,gBAAA;AADJ","sourcesContent":["ion-footer{\r\n    ion-toolbar {\r\n        --background: transparent;\r\n        --ion-color-base: transparent !important;\r\n    }\r\n}\r\n\r\nion-item{\r\n    &.no-border{\r\n        --border-color: transparent;\r\n    }\r\n}\r\n.total_price{\r\n    color: var(--ion-color-primary);\r\n    font-weight: bold;\r\n    font-size: 1.5rem;\r\n}\r\n\r\n.row-title{\r\n  color: var(--ion-color-primary);\r\n}\r\n\r\n.btn-promotional-code{\r\n    text-decoration: underline;\r\n}\r\n.btn-delete-promotional-code{\r\n    color: var(--ion-color-danger);\r\n    text-decoration: underline;\r\n}\r\n.text-discount{\r\n    color: var(--ion-color-success);\r\n}\r\n.title-promotional-code{\r\n    font-style: italic;\r\n}\r\n.item.sc-ion-label-ios-h, .item .sc-ion-label-ios-h, .item.sc-ion-label-md-h, .item .sc-ion-label-md-h{\r\n    white-space: normal;\r\n}\r\n\r\n// Pantallas movil pequeñas (Ej.: Iphone 5)\r\n@media (max-width: 330px){}\r\n\r\n.alert-ticket-personal-data .alert-wrapper {\r\n    overflow: scroll;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
