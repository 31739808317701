import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Storage } from "@ionic/storage";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { User } from '../interfaces/users';
// import { resolve } from "dns";
import { NavController } from '@ionic/angular';
import { Router } from "@angular/router";
import {SharedService} from "../services/shared.service";

@Injectable({
    providedIn: "root",
})
export class LoginService {
    constructor(
        private http: HttpClient,
        // private storage: Storage,
        public navCtrl: NavController,
        public router: Router,
        private sharedService: SharedService
    ) {}

    token: any;
    isLoggedIn = false;
    user: User;


    getUserSession(){
        this.user = JSON.parse(localStorage.getItem('user'));
        if(this.user == null){
            this.user = new User('', '', '', '', '', false);
            this.user.setEstado('');
            this.user.setEstadoInicioFin('','');
        }
        return this.user;
    }

    setUserData(data_array){
        this.user = new User(data_array['id'], data_array['name'], data_array['role_id'], data_array['email'], data_array['avatar'], data_array['accept_notifications']);

        this.user.setEstado(data_array['estado']);
        this.user.setEstadoInicioFin(data_array['startdate_descanso'], data_array['enddate_descanso']);


        if(data_array['showBooking'] != undefined){
            this.user.showBooking = data_array['showBooking'];
        }

        if(data_array['sessiones'] != undefined){
            this.user.sessiones.push(...data_array['sessiones']);
            // this.user.sessiones = data_array['sessiones'];
        }

        this.setUser(this.user);
        localStorage.setItem('accept_policy', data_array['accept_policy']);
        localStorage.setItem('accept_tutorial', data_array['accept_tutorial']);
        if(data_array['city_id']){
            localStorage.setItem('city_id', data_array['city_id']);
        }
        return this.user;
    }

    /**
     * Se encarga de actualizar los datos de estado y fehcas de estado de descanso, ademas de las sessiones de usuario
     * @param data_array
     */
    setUserDataEstadoSessiones(data_array){

        this.user = JSON.parse(localStorage.getItem('user'));
        if (this.user != null){
            this.user = new User(this.user.id, this.user.name, this.user.role_id, this.user.email, this.user.avatar, this.user.accept_notifications);
            this.user.setEstado(data_array['estado']);
            this.user.setEstadoInicioFin(data_array['startdate_descanso'], data_array['enddate_descanso']);

            if (data_array['showBooking'] != undefined){
                this.user.showBooking = data_array['showBooking'];
            }

            if (data_array['sessiones'] != undefined){
                this.user.sessiones = [];
                this.user.sessiones.push(...data_array['sessiones']);
                this.setUser(this.user);

                console.log("llamar a actualizar sessiones");
                // atraves de un evento actualizar contador de sessiones de menu tab
                this.sharedService.callTabFunction();
            }

        }
    }

    setUser(user){
        localStorage.setItem('user', JSON.stringify(user));
    }

    getToken() {
        return localStorage.getItem('token') || null;
    }

    setToken(token){
        // De momento lo guardo en dos tipos de local storage (solo debería dejar uno: el localStorage)
        // this.storage.set('token_auth', token); // Se utliza en las consultas a la api
        localStorage.setItem('token', token); // Se utiliza para comporbar si el usuario está logueado
    }

    cleanToken(){
        // Eliminar token
        // this.storage.remove("token_auth");
        localStorage.removeItem("token");
    }

    logout(){

        const url = environment.api;

        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.getToken()
        });

        const params = {};

        return this.http.post(url + 'logout', params, { headers });
    }

    closeSession(){
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        // localStorage.removeItem('accept_policy');
        // localStorage.removeItem('accept_tutorial');
        // localStorage.removeItem('history_search');

        //this.navCtrl.navigateRoot('/');
        this.router.navigate(['/'], { replaceUrl: true });
    }

    // Ralizar las llamadas
    getLogin(email: string, password: string) {
        const url = environment.api;

        const headers = new HttpHeaders({
            "Content-Type": "application/json",
        });

        const params = {
            email,
            password,
        };

        return this.http.post(url + "login", params, { headers })
            .pipe(
                map( resp => {
                    if(resp['isOK']){
                        this.setToken(resp['token'].token);
                        this.setUserData(resp['user']);
                    }
                    return resp;
                })
            );
    }

    // Ralizar las llamadas
    getLoginApple(provider: string, providerId: string, email: string, imageURL: string, name: string) {
        const url = environment.api;

        const headers = new HttpHeaders({
            "Content-Type": "application/json",
        });

        const params = {
            provider,
            providerId,
            email,
            imageURL,
            name,
        };

        return this.http.post(url + "loginApple", params, { headers })
            .pipe(
                map( resp => {
                    if(resp['isOK']){
                        this.setToken(resp['token'].token);
                        this.setUserData(resp['user']);
                    }
                    return resp;
                })
            );
    }


    // Ralizar las llamadas
    getLoginGmail(provider: string, providerId: string, email: string, imageURL: string, name: string) {
        const url = environment.api;

        const headers = new HttpHeaders({
            "Content-Type": "application/json",
        });

        const params = {
            provider,
            providerId,
            email,
            imageURL,
            name,
        };

        return this.http.post(url + "loginGmail", params, { headers })
            .pipe(
                map( resp => {
                    if(resp['isOK']){
                        this.setToken(resp['token'].token);
                        this.setUserData(resp['user']);
                    }
                    return resp;
                })
            );
    }



    getUser() {
        const query = "get_user";
        const url = environment.api;
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.getToken(),
        });

        return this.http.get(url + query, { headers });
    }

    getPolicy(){
        return (localStorage.getItem('accept_policy') == '1') ? true : false;
    }

    getTutorial(){
        return (localStorage.getItem('accept_tutorial') == '1') ? true : false;
    }

    getCity(){
        let city_id = localStorage.getItem('city_id');
        return (city_id) ? city_id : false;
    }

    getCityName(){
        let city_name = localStorage.getItem('city_name');
        return (city_name) ? city_name : null;
    }

    rememberPassword(filters = {}) {
        const query = "remember_password";
        const url = environment.api;
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
        });

        const params = {
            filters,
        };

        return this.http.post(url + query, params, { headers });
    }

    createUser(data) {
        const query = "create_user";
        const url = environment.api;
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
        });

        // Enviar también los parametros de configuración guardados en el localStorage
        data['config'] = {
            'accept_policy': this.getPolicy(),
            'accept_tutorial': this.getTutorial(),
            'city_id': this.getCity(),
        };

        const params = {
            data,
        };

        return this.http.post(url + query, params, { headers })
                .pipe(
                    map( resp => {
                        // Si el registro es correcto el ususario ya viene logueado
                        if(resp['isOK']){
                            this.setToken(resp['token'].token);
                            this.setUserData(resp['user']);
                        }
                        return resp;
                    })
                );
    }

    getEnableNotifications(accept_notifications) {
        const query = "enable_notifications";
        const url = environment.api;
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.getToken(),
        });

        const params = {
            accept_notifications: accept_notifications,
        };

        return this.http.post(url + query, params, { headers });
    }

    updateUserData(data) {
        const query = "update_user";
        const url = environment.api;
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.getToken(),
        });

        const params = {
            data,
        };

        return this.http.post(url + query, params, { headers });
    }

    updatePassword(data) {
        const query = "update_password";
        const url = environment.api;
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.getToken(),
        });

        const params = {
            data,
        };

        return this.http.post(url + query, params, { headers });
    }

    /**
     * Registrar el token de acceso a dispositivo con firebase para notificaciones.
     * @param data
     */
    registerFirebaseToken(data) {
        console.log('registerFirebaseToken');
        const query = "save_token";
        const url = environment.api;
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.getToken(),
        });

        const params = {
            data,
        };

        return this.http.post(url + query, params, { headers });
    }


    // Comprobar si hay token de usuario y es válido, para ello tenemos que utilizar una Promesa
    validateToken(is_admin = false):Promise<boolean>{

        if(!this.getToken()){
            this.navCtrl.navigateForward('/login/0/1');
            return Promise.resolve(false);
        }

        return new Promise<boolean>(resolve => {
            const query = "get_user";
            const url = environment.api;
            const headers = new HttpHeaders({
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.getToken(),
            });

            this.http.get(url + query, { headers })
                .subscribe(respuesta => {
                    if(respuesta['success']){
                        this.setUserData(respuesta['data']);
                        if(is_admin){
                            // Comprobar acceso al backend
                            if(this.is_admin(respuesta['data']['role_id']) || this.is_user_business(respuesta['data']['role_id'])){
                                // Rol admin o rol business
                                resolve(true);
                            }else{
                                this.navCtrl.back();
                                resolve(false);
                            }
                        }else{
                            resolve(true);
                        }
                    }else{
                        this.navCtrl.navigateForward('/login/0/1');
                        resolve(false);
                    }
                });
        });
    }

    // El usuario acepta la politica
    acceptPolicy():Promise<boolean>{
        localStorage.setItem('accept_policy', '1');

        if(this.getToken()){
            // guardar en BD
            return new Promise<boolean>(resolve => {
                const query = "accept_policy";
                const url = environment.api;
                const headers = new HttpHeaders({
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.getToken(),
                });

                const params = {
                    'accept_policy': 1,
                };

                this.http.post(url + query, params, { headers })
                    .subscribe(respuesta => {
                        if(respuesta['success']){
                            resolve(true);
                        }else{
                            resolve(false);
                        }
                    });
            });
        }else{
            return Promise.resolve(true);
        }
    }

    // El usuario ya ha visto el tutorial
    acceptTutorial():Promise<boolean>{
        localStorage.setItem('accept_tutorial', '1');

        if(this.getToken()){
            // guardar en BD
            return new Promise<boolean>(resolve => {
                const query = "accept_tutorial";
                const url = environment.api;
                const headers = new HttpHeaders({
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.getToken(),
                });

                const params = {
                    'accept_tutorial': 1,
                };

                this.http.post(url + query, params, { headers })
                    .subscribe(respuesta => {
                        if(respuesta['success']){
                            resolve(true);
                        }else{
                            resolve(false);
                        }
                    });
            });
        }else{
            return Promise.resolve(true);
        }
    }

    getCitiesInApp(){
        const query = "show_cities_in_app";
        const url = environment.api;
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
        });

        return this.http.get(url + query, { headers });
    }

    getPolicyApp(){
        const query = "get_policy";
        const url = environment.api;
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
        });

        return this.http.get(url + query, { headers });
    }

    getTutorialApp(){
        const query = "get_tutorial";
        const url = environment.api;
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
        });

        return this.http.get(url + query, { headers });
    }

    selectCityInApp(city_id:string, name:string){
        localStorage.setItem('city_id', city_id);
        localStorage.setItem('city_name', name);

        if(this.getToken()){
            // guardar en BD
            return new Promise<boolean>(resolve => {
                const query = "select_city_in_app/" + city_id;
                const url = environment.api;
                const headers = new HttpHeaders({
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.getToken(),
                });

                this.http.get(url + query, { headers })
                    .subscribe(respuesta => {
                        if(respuesta['success']){
                            resolve(true);
                        }else{
                            resolve(false);
                        }
                    });
            });
        }else{
            return Promise.resolve(true);
        }
    }

    // Usuarios del negocio
    is_user_business(role_id){
        if(this.is_admin_business(role_id) || this.is_employee(role_id)){
          return true;
        }else{
          return false;
        }
    }

    // Admin de la app
    is_admin(role_id){
        if(role_id == 1){
            return true;
        }else{
            return false;
        }
    }

    // Usuario normal de la app
    is_normal_user(role_id){
        if(role_id == 2){
            return true;
        }else{
            return false;
        }
    }

    // Empleado del negocio
    is_employee(role_id){
        if(role_id == 4){
            return true;
        }else{
            return false;
        }
    }

    // Admin del negocio
    is_admin_business(role_id){
        if(role_id == 3){
            return true;
        }else{
            return false;
        }
    }

    // Eliminar cuenta de usuario
    deleteUser(user_id){
        const url = environment.api;
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.getToken()
        });

        return this.http.get(url + 'delete_user/' + user_id, { headers });
    }

}
