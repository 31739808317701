// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.white-space-normal {
  white-space: normal;
}`, "",{"version":3,"sources":["webpack://./src/app/components/form-business-address/form-business-address.component.scss"],"names":[],"mappings":"AACA;EACI,mBAAA;AAAJ","sourcesContent":["//.item.sc-ion-label-md-h, .item .sc-ion-label-md-h, .item.sc-ion-label-ios-h, .item .sc-ion-label-ios-h{\r\n.white-space-normal{\r\n    white-space: normal;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
