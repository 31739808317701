// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-slides, ion-slide {
  height: 100%;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/admin/validate-code-modal/validate-code-modal.page.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,WAAA;AACJ","sourcesContent":["ion-slides, ion-slide{\r\n    height: 100%;\r\n    width: 100%;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
