import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "../../environments/environment";
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {

  constructor(
    private http: HttpClient,
    private loginService: LoginService
  ) { }

  getToken() {
    return this.loginService.getToken();
  }

  // GET: Construir la llamada a la API
  getQuery(query: string) {
    const url = environment.api;
    const headers = new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.getToken(),
    });

    return this.http.get(url + query, { headers });
  }

  // POST: Construir la llamada a la API
  postQuery(query: string, params = {}) {
    const url = environment.api;
    const headers = new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.getToken(),
    });

    return this.http.post(url + query, params, { headers });
  }

  checkout(data) {
    const query = "checkout";

    const params = {
        data,
    };

    return this.postQuery(query, params);
  }

  confirmCheckout(data) {
    const query = "confirm_checkout";

    const params = {
        data,
    };

    return this.postQuery(query, params);
  }

  get_tickets(checkout_id, checkout_code){
    const query = "get_tickets/" + checkout_id + "/" + checkout_code;
    return this.getQuery(query);
  }

  generate_link_tickets_pdf(checkout_id){
    const query = "generate_link_tickets_pdf/" + checkout_id;
    return this.getQuery(query);
  }

  get_all_tickets_user(params){
    const query = "get_user_ads_tickets";
    console.log(params);
    return this.postQuery(query, params);
  }

  checkout_payment(data) {
    const query = "get_checkout_payment";

    return this.postQuery(query, data);
  }

  make_pay_card_stripe(data){
    const query = "checkout_stripe";

    const params = {
      data,
    };
    return this.postQuery(query, params);
  }

  check_refund_stripe(data){
    const query = "check_refund_stripe";

    const params = {
      data,
    };
    return this.postQuery(query, params);
  }

  cancel_payment(data){
    const query = "cancel_payment";

    return this.postQuery(query, data);
  }

  applyPromotionalCode(data) {
    const query = "checkout_apply_promotional_code";

    const params = {
        data,
    };

    return this.postQuery(query, params);
  }
}
