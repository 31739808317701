import { Component, Input, OnInit } from '@angular/core';
import { ModalController, LoadingController } from '@ionic/angular';
import { AdminService } from '../../../api/admin.service';

@Component({
  selector: 'app-validate-code-modal',
  templateUrl: './validate-code-modal.page.html',
  styleUrls: ['./validate-code-modal.page.scss'],
})
export class ValidateCodeModalPage implements OnInit {

  @Input() code:String;

  loading = true;
  loading_validate = false;
  ticket_ok = false;
  ticket = [];

  optionsSlide = {
    allowSlidePrev: false,
    allowSlideNext: false,
  };

  constructor(
    public modalController: ModalController,
    public adminService: AdminService,
    public loadingController: LoadingController
  ) { }

  ngOnInit() {
    this.loading = true;
    this.loadTicketCode(this.code);
  }

  async loadTicketCode(code){
    this.adminService.loadTicketCode(code)
    .subscribe(
      (response) => {
        this.loading = false;
        if(response['success']){
          this.ticket = response['data'];
          this.ticket_ok = true;
        }else{
          this.ticket_ok = false;
        }
      },
      (error) => {
        // error.message
        this.ticket_ok = false;
        console.log(error);
      }
    );
  }

  closeModal(params = null){
    if(params){
      this.modalController.dismiss(params);
    }else{
      this.modalController.dismiss();
    }
  }

  validateTicket(){
    this.loading_validate = true;
    this.adminService.validateTicket(this.code)
    .subscribe(
      (response) => {
        this.loading_validate = false;
        let params = {
          'message': response['data']['message'],
          'success': response['success'],
        };
        this.closeModal(params);
      },
      (error) => {
        // error.message
        console.log(error);
      }
    );
  }

}
