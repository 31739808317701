import { Component } from '@angular/core';

import { Platform, NavController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { TranslateService } from '@ngx-translate/core';
import { LoginService } from './api/login.service';
import { Router } from '@angular/router';

import {LanguageService} from './services/language.service';

import { Pages } from './interfaces/pages';
import { Location } from '@angular/common';
// import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  public appPages: Array<Pages>;
  // user;
  avatar: string;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public navCtrl: NavController,
    public translate: TranslateService,
    private languageService: LanguageService,
    private loginService: LoginService,
    public router: Router,
    public location: Location
    // public events: Events
  ) {

    // if (
    //     this.platform.is('ios')
    //     || this.platform.is('android')
    //     && !( this.platform.is('desktop') || this.platform.is('mobileweb') ) ) {
    //   alert('android o ios');
    // } else {
    //   GoogleAuth.init();
    // }

    // if ( this.platform.is('tablet') || this.platform.is('desktop') || this.platform.is('pwa') || this.platform.is('mobileweb') ) {
    //   GoogleAuth.init();
    // }

    console.log(this.platform.platforms());

    // translate.setDefaultLang('es');
    translate.setDefaultLang(this.languageService.getLanguage());

    this.appPages = [
      {
        title: 'Home',
        url: '/home-results',
        direct: 'root',
        icon: 'home'
      },
      {
        title: 'About',
        url: '/about',
        direct: 'forward',
        icon: 'information-circle-outline'
      },

      {
        title: 'App Settings',
        url: '/settings',
        direct: 'forward',
        icon: 'cog'
      }
    ];

    this.initializeApp();

    // router.events.subscribe(event => {
    //     //   if (event instanceof NavigationEnd) {
    //     //     this.ngOnInit();
    //     //   }
    //     // });

    // generamos un evento que lanzamos cuando queremos trabajar con el app nav menu
    /* this.events.subscribe('updateMenu', () => {
      console.log('force update the screen');
      this.ngOnInit();
    }); */
  }

  ngOnInit() {
    // Comprobar localstorage y obtener datos del usuario
    /* const localStorageUser = localStorage.getItem('user');
    if(localStorageUser != null) {
      const dataUser = JSON.parse(localStorage.getItem('user'));
      // console.log(dataUser);
      if (dataUser !== undefined && dataUser !== '') {
        this.user = dataUser;
        this.avatar = dataUser['avatar'];
      }
    } */
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleLightContent();
      this.splashScreen.hide();

      /* const dataUser = JSON.parse(localStorage.getItem('user'));
      console.log(dataUser);
      if (dataUser !== undefined && dataUser !== '') {
        this.user = dataUser;
        this.avatar = dataUser['avatar'];
      } */
    }).catch(() => {});

    // Para salirnos completamente de la app desde el smartphone (android) cuando se pulsa el botón atrás
    this.platform.backButton.subscribeWithPriority(10, (processNextHandler) => {
      // Cerrar la aplicación desde las rutas indicadas
      if (this.location.isCurrentPathEqualTo('/tabs/home') || this.location.isCurrentPathEqualTo('/splash')) {
        navigator['app'].exitApp();
      } else {
        // Navigate to back page
        this.location.back();
      }
    });
  }

  goToEditProgile() {
    this.navCtrl.navigateForward('edit-profile');
  }

  logout() {
    console.log("logout");

    this.loginService.logout()
        .subscribe(
            (response) => {

              // Eliminar datos del login
              this.loginService.cleanToken();

              this.navCtrl.navigateRoot('/');
            },
            (error) => {
              console.log('bad logout: ');
              console.log(error);
            }
        );
  }// logout
}
