// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h2 {
  color: var(--ion-color-primary);
}

.div-no-data {
  padding-top: 5%;
}

.icon-no-data {
  color: var(--ion-color-primary);
  font-size: 4em;
}`, "",{"version":3,"sources":["webpack://./src/app/components/no-data/no-data.component.scss"],"names":[],"mappings":"AAAA;EACI,+BAAA;AACJ;;AACA;EACI,eAAA;AAEJ;;AAAA;EACI,+BAAA;EACA,cAAA;AAGJ","sourcesContent":["h2{\r\n    color: var(--ion-color-primary)\r\n}\r\n.div-no-data{\r\n    padding-top: 5%;\r\n}\r\n.icon-no-data{\r\n    color: var(--ion-color-primary);\r\n    font-size: 4em;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
