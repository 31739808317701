import { Component, Input, OnInit } from '@angular/core';

import { DatePipe } from '@angular/common';

// Camara
// import { Plugins, CameraResultType, CameraSource } from '@capacitor/core';
import { Camera, CameraResultType } from '@capacitor/camera';

import { ModalController, AlertController, LoadingController } from '@ionic/angular';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FunctionsService } from '../../../services/functions.service';
import { AdminService } from '../../../api/admin.service';

@Component({
  selector: 'app-new-ad',
  templateUrl: './new-ad.page.html',
  styleUrls: ['./new-ad.page.scss'],
})
export class NewAdPage implements OnInit {
  @Input() ad_id;
  @Input() type_id;

  public adForm: FormGroup;

  show_form = false;
  is_event = false;
  updateAd = false; // Formulario para actualizar datos
  formValid = false;
  show_form_address = false;
  base64Image = null;
  images = [];
  dates = [];
  tickets = [];
  list_business = [];
  list_categories = [];
  dates_deleted = []; // Se utiliza cuando editamos un anuncio ya guardado en BD
  tickets_deleted = []; // Se utiliza cuando editamos un anuncio ya guardado en BD
  business_address = [];

  constructor(
    public modalController: ModalController,
    private formBuilder: FormBuilder,
    public alertController: AlertController,
    private datePipe: DatePipe,
    public functionsService: FunctionsService,
    public adminService:AdminService,
    public loadingCtrl: LoadingController
  ) { }


  ngOnInit() {
    // Cargar el formulario
    if(this.ad_id){
      this.show_form = true;
      this.buildForm();
    }
  }

  get validateEmailLogin(){
    return this.adForm.get('email').invalid && this.adForm.get('email').touched
  }

  buildForm(){
    this.is_event = this.functionsService.isEvent(this.type_id);

    // Validaciones del formulario
    this.adForm = this.formBuilder.group({
      'title': [null, Validators.compose([
        Validators.required,
        Validators.maxLength(50),
      ])],
      /* 'tag': [null, Validators.compose([
        Validators.required,
        Validators.maxLength(15),
      ])], */
      'description': [null, Validators.compose([
        Validators.required,
        Validators.maxLength(50000),
      ])],
      'category_id': [null, Validators.compose([
        Validators.required
      ])],
      'tag': [null, Validators.compose([])],
      'pay_in_app': [null, Validators.compose([])],
      'management_costs_to_client': [null, Validators.compose([])],
      'business_id': [null, Validators.compose([])],
      'approximate_duration': [null, Validators.compose([])],
      'door_opening': [null, Validators.compose([])],
      'age_policy': [null, Validators.compose([])],
      'warning_message': [null, Validators.compose([])],
      'search_tags': [null, Validators.compose([])],
    },{
      validators: this.formDataRequired('tag')
    });

    // Cargar datos
    this.setDataForm();
  }

  formDataRequired(tag:string){
    return (adForm: FormGroup) => {
      const data_tag = adForm.controls[tag];

      let tag_value = new String(data_tag.value);
      if(tag_value == '' && !this.is_event){
        data_tag.setErrors({required: true});
      }else if(tag_value.length > 15 && !this.is_event){
        data_tag.setErrors({max_length: true});
      }else{
        data_tag.setErrors(null);
      }
    }
  }

  async setDataForm(){
    // Mostrar cargando
    const loader = await this.loadingCtrl.create();

    // Cargar datos del formulario
    this.adminService.buildFormAd(this.type_id, this.ad_id)
    .subscribe(
      (response) => {
        if(response['success']){
          console.log(response);
          this.list_business = response['data']['business'];
          this.list_categories = response['data']['categories'];
          this.business_address = response['data']['business_address'];
          if(response['data']['form_edit'] != undefined){
            // Datos para autocompletar el formulario
            this.adForm.reset({
              'title': response['data']['form_edit']['ad']['title'],
              'tag': response['data']['form_edit']['ad']['tag'],
              'description': response['data']['form_edit']['ad']['description'],
              'category_id': response['data']['form_edit']['ad']['category_id'],
              'business_id': response['data']['form_edit']['business_id'],
              'pay_in_app': response['data']['form_edit']['ad']['pay_in_app'],
              'management_costs_to_client': response['data']['form_edit']['ad']['management_costs_to_client'],
              //'name_site': response['data']['form_edit']['ad']['name_site'],
              //'address': response['data']['form_edit']['ad']['address'],
              'approximate_duration': response['data']['form_edit']['ad']['approximate_duration'],
              'door_opening': response['data']['form_edit']['ad']['door_opening'],
              'age_policy': response['data']['form_edit']['ad']['age_policy'],
              'warning_message': response['data']['form_edit']['ad']['warning_message'],
              'search_tags': response['data']['form_edit']['ad']['search_tags'],
            });

            this.tickets = response['data']['form_edit']['tickets'];
            this.dates = response['data']['form_edit']['dates'];
            this.images = response['data']['form_edit']['ad']['all_images'];

            this.updateAd = true;

          }else{
            // Datos para autocompletar el formulario
            this.adForm.reset({
              'pay_in_app': 1,
              'management_costs_to_client': 1,
            });
          }
          loader.dismiss().then(async l => {}); // Ocultar cargando
        }else{
          loader.dismiss().then(async l => {}); // Ocultar cargando
          this.closeModal();
        }
      },
      (error) => {
        loader.dismiss().then(async l => {}); // Ocultar cargando
        console.log(error);
      }
    );
  }

  closeModal(params = {}){
    if(this.show_form_address){
      this.show_form = true;
      this.show_form_address = false;
    }
    else if(!this.ad_id && this.type_id){
      this.type_id = null;
      this.show_form = false;
    }else{
      this.modalController.dismiss(params);
    }
  }

  async openCamera(i = 0){
    console.log(i);
     try {
      const profilePicture = await Camera.getPhoto({
        quality: 90,
        allowEditing: false,
        resultType: CameraResultType.Base64,
        saveToGallery: true,
        width: 450,
        height: 300,
        promptLabelPhoto: 'Abrir galería',
        promptLabelPicture: 'Hacer foto'
      });

      if(profilePicture.format == 'jpeg' || profilePicture.format == 'png'){
        let base64Image = profilePicture.base64String;
        this.images[i] = 'data:image/jpeg;base64,' + base64Image;
      }else{
        this.functionsService.showDangerToast('Formato no permitido');
      }

    } catch (error) {
      console.error(error);
    }
  }

  removePhoto(i){
    this.images[i] = null;
  }


  /*********************/
  /****** FECHAS *******/
  /*********************/

  async alertDate(data = [], update_key = -1){
    /* let currentDate = new Date();
    let nextDate = new Date(currentDate.getFullYear() + 1, currentDate.getMonth(), currentDate.getDay()); // Sumamos 1 año
    let minDate = this.datePipe.transform(currentDate, 'yyyy-MM-dd');
    let maxDate = this.datePipe.transform(nextDate, 'yyyy-MM-dd'); */

    // Obtener el id de la fecha del anuncio si se está editando un anuncio ya creado
    let ad_date_id = null;
    if(data['id']){
      ad_date_id = data['id'];
    }

    const alert = await this.alertController.create({
      cssClass: 'alert-ads-date-data',
      header: 'Añadir fechas del anuncio',
      backdropDismiss: false,
      inputs: [
        {
          value: 'Fecha del evento*',
          name: 'a0',
          type: 'text',
          cssClass: (this.is_event) ? 'input-label' : 'input-label input-hidden',
        },
        {
          name: 'date',
          type: 'datetime-local',
          cssClass: (this.is_event) ? '' : 'input-hidden',
          //min: minDate, // solo funciona con el type: "date"
          //max: maxDate,
          value: (data['date'] != undefined) ? data['date'].replace(' ', 'T') : null, //'2016-10-09T15:38:00' (tiene que se en formato UTC)
        },
        {
          value: (this.is_event) ? 'F. inicio de venta entradas*' : 'Fecha de inicio*',
          name: 'a1',
          type: 'text',
          cssClass: 'input-label',
        },
        {
          name: 'start_date',
          type: 'datetime-local',
          //min: minDate, // solo funciona con el type: "date"
          //max: maxDate,
          value: (data['start_date'] != undefined) ? data['start_date'].replace(' ', 'T') : null, //'2016-10-09T15:38:00' (tiene que se en formato UTC)
        },
        {
          value: (this.is_event) ? 'F. fin de venta entradas' :'Fecha de fin*',
          name: 'a2',
          type: 'text',
          cssClass: 'input-label',
        },
        {
          name: 'end_date',
          type: 'datetime-local',
          //min: minDate,
          //max: maxDate,
          value: (data['end_date'] != undefined) ? data['end_date'].replace(' ', 'T') : null,
        },
        {
          value: 'F. caducidad para validar los tickets*',
          name: 'a3',
          type: 'text',
          cssClass: 'input-label',
        },
        {
          name: 'expiration_date',
          type: 'datetime-local',
          //min: minDate,
          //max: maxDate,
          value: (data['expiration_date'] != undefined) ? data['expiration_date'].replace(' ', 'T') : null,
        },
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            return;
          }
        }, {
          text: 'Añadir',
          handler: (event) => {
            this.addEditDate(event, update_key, ad_date_id);
          }
        }
      ]
    });

    await alert.present();

  }

  addEditDate(data, update_key, ad_date_id = null){
    if(data.start_date != '' && data.end_date != '' && data.expiration_date != '' &&
    ((this.is_event && data.date != '') || !this.is_event)){
      let object_date = {
        'date': data.date,
        'start_date': data.start_date,
        'end_date': data.end_date,
        'expiration_date': data.expiration_date,
        'id': ad_date_id, // Si es diferente de null significa que estamos editando una fecha ya creada en la BD
      };

      if(update_key >= 0){
        // Actualizar datos
        this.dates[update_key] = object_date;
      }else{
        // Nuevos datos
        this.dates.push(object_date);
      }
    }else{
      this.functionsService.showDangerToast('Completa todas las fechas');
    }
  }

  async removeDate(key){
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Eliminar fecha',
      message: '¿Esta seguro?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
          }
        }, {
          text: 'Eliminar',
          handler: () => {
            if(this.dates[key]['id'] != undefined){
              this.dates_deleted.push(this.dates[key]['id']);
            }
            this.dates.splice(key, 1);
          }
        }
      ]
    });

    await alert.present();
  }

  /*********************/
  /****** TICKETS ******/
  /*********************/

  async alertTicket(data = [], update_key = -1){

    // Obtener el id del ticket del anuncio si se está editando un anuncio ya creado
    let ad_ticket_id = null;
    if(data['id']){
      ad_ticket_id = data['id'];
    }

    const alert = await this.alertController.create({
      cssClass: 'alert-ads-ticket-data',
      header: 'Añadir fechas del anuncio',
      backdropDismiss: false,
      inputs: [
        {
          value: 'Nombre del ticket*',
          name: 'a1',
          type: 'text',
          cssClass: 'input-label',
        },
        {
          name: 'name',
          type: 'text',
          value: (data['name'] != undefined) ? data['name'] : null,
        },
        {
          value: 'Descripción (opcional)',
          name: 'a2',
          type: 'text',
          cssClass: 'input-label',
        },
        {
          name: 'description',
          type: 'textarea',
          value: (data['description'] != undefined) ? data['description'] : null,
        },
        {
          value: (this.is_event) ? 'Precio ticket* (€)' : 'Precio € (opcional)',
          name: 'a3',
          type: 'text',
          cssClass: 'input-label',
        },
        {
          name: 'pvp_price',
          type: 'number',
          value: (data['stock'] != undefined) ? data['stock'] : null,
        },
        {
          value: 'Stock del ticket*',
          name: 'a4',
          type: 'text',
          cssClass: 'input-label',
        },
        {
          name: 'stock',
          type: 'number',
          value: (data['stock'] != undefined) ? data['stock'] : null,
        },
        {
          value: 'Inicio numeración ticket*',
          name: 'a4',
          type: 'text',
          cssClass: 'input-label',
        },
        {
          name: 'start_number_ticket',
          type: 'number',
          value: (data['start_number_ticket'] != undefined) ? data['start_number_ticket'] : 1,
        },
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            return;
          }
        }, {
          text: 'Añadir',
          handler: (event) => {
            this.addEditTicket(event, update_key, ad_ticket_id);
          }
        }
      ]
    });

    await alert.present();

  }

  addEditTicket(data, update_key, ad_ticket_id = null){
    if(data.name != '' && data.stock != ''){
      let object_ticket = {
        'name': data.name,
        'description': data.description,
        'stock': data.stock,
        'pvp_price': data.pvp_price,
        'start_number_ticket': data.start_number_ticket,
        'id': ad_ticket_id, // Si es diferente de null significa que estamos editando una fecha ya creada en la BD
      };

      if(update_key >= 0){
        // Actualizar datos
        this.tickets[update_key] = object_ticket;
      }else{
        // Nuevos datos
        this.tickets.push(object_ticket);
      }
    }else{
      this.functionsService.showDangerToast('Completa los datos obligatorios');
    }
  }

  async removeTicket(key){
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Eliminar ticket',
      message: '¿Esta seguro?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
          }
        }, {
          text: 'Eliminar',
          handler: () => {
            if(this.tickets[key]['id'] != undefined){
              this.tickets_deleted.push(this.tickets[key]['id']);
            }
            this.tickets.splice(key, 1);
          }
        }
      ]
    });

    await alert.present();
  }

  /*********************/
  /****** GUARDAR ******/
  /*********************/

  async save(){
    // Validar formulario
    await this.validateForm();

    if(this.formValid){
      // Enviar datos del formulario a la api
      // console.log(this.adForm);
      let data = {
        'ad': this.adForm.value,
        'business_address_id': this.business_address['id'],
        'type_id': this.type_id,
        'images': this.images,
        'dates': this.dates,
        'dates_deleted': this.dates_deleted,
        'tickets': this.tickets,
        'tickets_deleted': this.tickets_deleted,
        'ads_id': this.ad_id // Si es distinto de null significa que estamos editando
      };

      let error_message = null;

      // Mostrar cargando
      const loader = await this.loadingCtrl.create();
      loader.present();

      this.adminService.createUpdateAd(data, this.updateAd)
      .subscribe(
        (response) => {
          loader.dismiss().then(async l => {}); // Ocultar cargando

          if(response['success']){
            let ads_id = response['data']['ads_id']; // Id del anuncio creado
            if(this.updateAd){
              this.functionsService.showToast('Anuncio actualizado correctamente');
            }else{
              this.functionsService.showToast('Anuncio creado correctamente');
            }

            // Redirigir a la pantalla de configuración del anuncio
            this.closeModal({
              'ads_id': ads_id,
              'update_ad': this.updateAd
            });
          }else{
            this.functionsService.showDangerToast(response['data']['error_message']);
          }
        },
        (error) => {
          loader.dismiss().then(async l => {}); // Ocultar cargando
          this.functionsService.showDangerToast(error);
        }
      );
    }
  }

  async validateForm(){
    let messages = '';
    if(!(this.images.length > 0 && this.images[0] != undefined && this.images[0] != '')){
      messages += '<li>Es obligatorio añadir la imagen principal del anuncio</li>';
    }
    if(!this.adForm.valid){
      messages += '<li>Los detalles del anuncio son obligatorios</li>';
    }
    if(this.list_business.length > 0 && this.adForm.value.business_id == null){
      messages += '<li>El negocio es obligatorio</li>';
    }
    if(this.dates.length == 0){
      messages += '<li>Es obligatorio indicar al menos un intervalo de fechas para el anuncio</li>';
    }
    if(this.tickets.length == 0){
      messages += '<li>Es obligatorio crear al menos un ticket para el anuncio</li>';
    }

    if(messages == ''){
      this.formValid = true;
    }else{
      const alert = await this.alertController.create({
        cssClass: 'my-custom-class',
        header: 'Aviso',
        subHeader: 'Corrige los siguientes errores:',
        message: '<ul>'+messages+'</ul>',
        buttons: ['OK']
      });

      await alert.present();
    }
  }

  chooseTypeAd(type){
    this.type_id = type;
    this.show_form = true;
    this.buildForm();
  }

  showFormAddress(){
    this.show_form_address = true;
  }

  setAddressSelected(event){
    this.business_address = event;

    // Cerrar componente app-form-business-address
    this.show_form_address = false;
  }

}
